<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-1">
              <div class="col-12 section_title">CREAR PRODUCTO</div>
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="GUARDAR"
              icon="pi pi-save"
              class="p-button-success mr-2"
              @click="save()"
            />
          </template>
        </Toolbar>

        <div class="">
          <div class="">
            <div class="formgrid grid">
              <div class="field col-2">
                <label for="name">PRODUCTO</label>
                <InputText
                  v-model="form.name"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>

              <div class="field col-2">
                <label for="material" style="display: block">MATERIAL</label>
                <Dropdown
                  id="material"
                  v-model="form.material"
                  :options="materials"
                  class="w-full"
                  optionLabel="name"
                  optionValue="id"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !form.material }"
                />
              </div>
            </div>
          </div>

          <div class="">
            <div class="formgrid grid">
              <div class="field col">
                <label for="detail">Tipo </label>
                <SelectButton
                  v-model="form.tipo"
                  :options="tipos"
                  optionValue="value"
                  dataKey="value"
                  optionLabel="label"
                >
                </SelectButton>
              </div>
            </div>
          </div>
          <div class="" v-if="form.tipo == 0">
            <div class="formgrid grid">
              <div class="field col-1">
                <label for="alto">Alto</label>
                <InputText
                  v-model="form.alto"
                  id="alto"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col-1">
                <label for="ancho">Ancho</label>
                <InputText
                  v-model="form.ancho"
                  id="ancho"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col-1">
                <label for="base">Espesor</label>
                <InputText
                  v-model="form.espesor"
                  id="base"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col-1">
                <label for="base">Plisas</label>
                <InputText
                  v-model="form.plisas"
                  id="plisas"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col">
                <label for="calidad">Calidad</label>
                <SelectButton
                  v-model="form.calidad"
                  :options="calidades"
                  optionValue="value"
                  dataKey="value"
                  optionLabel="label"
                >
                </SelectButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="my-1">
          <div class="col-12 section_subtitle">MOD <small>Mano de Obra Directa</small></div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col-6">
              <label for="base">Mano de obra</label>
              <AutoComplete
                v-model="selectedMOD"
                :suggestions="filteredMod"
                @complete="searchMDO($event)"
                field="name"
                :dropdown="true"
                @item-select="newItemMOD = selectedMOD"
                id="base"
                class="inputfield w-full"
              />

            </div>
            <div class="field col-3">
                <label for="formu">Minutos</label>
                <InputText
                 :disabled="!newItemMOD"
                  v-model="actualFormulaMOD"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                />
              </div>

        <div class="field col-3">
        
            <Button :disabled="!actualFormulaMOD"  @click="addItemMOD(newItemMOD)" label="Agregar" 
              icon="pi pi-plus" class="p-button-success inputfield w-full mt-5" />

        </div>



          </div>

          <DataTable
            :value="mod"
            dataKey="id" 
            class="p-datatable-sm"
            responsiveLayout="scroll"
          >
            <Column field="id" header="" >
              <template #body="slotProps">
                  <Button icon="pi pi-times" class="p-button-rounded p-button-danger" @click="delItemMOD(slotProps.data.id)"></Button>
              </template>
            </Column>
            <Column field="qty" header="Minutos"></Column>
            <Column field="name" header="Detalle">
            <template #body="slotProps">
              <b>
                {{ slotProps.data.name }}
              </b>
              <small class="ml-2">
                {{ slotProps.data.description }}
              </small>

            </template>
            </Column>
            <Column field="valor" header="Valor x Min">
            <template #body="slotProps">
                    <div class="">
                      <div>
                        <span>{{toUsd(slotProps.data.valor )}}</span>
                      </div>
                      <div>
                        <span>{{toArs(slotProps.data.valor )}}</span>
                      </div>
                    </div>
                
            </template>
            </Column>
            <Column field="" header="Subtotal">
                <template #body="slotProps">
                    <div class="flex justify-content-between">
                      <div>
                        <span>{{toUsd(slotProps.data.valor * slotProps.data.qty )}}</span>
                      </div>
                      <div>
                        <span>&nbsp;&nbsp;{{toArs(slotProps.data.valor * slotProps.data.qty )}}</span>
                      </div>
                    </div>
                
                </template>
            ></Column>
          </DataTable>

        </div>


      </div>  

            
      <div class="card">
        <div class="my-1">
          <div class="col-12 section_subtitle">MPD <small>Materia Prima Directa</small></div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col-5">
              <label for="base">Insumo</label>
              <AutoComplete
                v-model="selectedProduct"
                :suggestions="filteredProducts"
                @complete="searchProduct($event)"
                field="name"
                :dropdown="true"
                @item-select="newItem = selectedProduct"
                id="base"
                class="inputfield w-full"
              />

            </div>
            <div class="field col-4">
                <label for="formu">Formula / Cantidad</label>
                <InputText
                 :disabled="!newItem"
                  v-model="actualFormula"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                  placeholder="=SI(espesor>=30; 300/espesor; espesor*0.17)"
                />
              </div>

            <div class="field col-2" v-if="calcQuantity > 0">
                <!-- <Dropdown
                  id="mod"
                  v-model="newMod"
                  :options="mod"
                  class="w-full"
                  optionLabel="name"
                  optionValue="id"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !form.mod }"
                /> -->
                <label for="formu">Cantidad calculada:</label>
             <div>
              <h5  style="line-height: 40px;">{{ calcQuantity }}
                <span style="text-transform: uppercase; font-weight: normal; font-size: 13px;">
                  {{formatUnity(this.newItem.unidad)}}
                </span>
              </h5>
            </div>
          </div>
          <div class="field col-3">        
            <Button :disabled="!actualFormula"  @click="addItem(newItem)" label="Agregar" 
              icon="pi pi-plus" class="p-button-success inputfield w-full mt-5" />

        </div>



          </div>
        </div>

        <div class="">
          <DataTable
            :value="items"
             dataKey="id" 
            class="p-datatable-sm"
            responsiveLayout="scroll"
          >
            <template #header> Insumos </template>
            <Column header="" >
              <template #body="slotProps">
                  <Button icon="pi pi-times" class="p-button-rounded p-button-danger" @click="delItem(slotProps.data)"></Button>
              </template>
            </Column>
           <Column headerClass="" field="qty" header="Cantidad" headerStyle="max-width: 20%">
              <template #body="slotProps">
                {{ slotProps.data.qty }} <span style="text-transform: uppercase; margin-left: 10px; font-weight:bolder;">{{formatUnity(slotProps.data.unidad)}}</span>


                <span class="ml-2" >
                  <small>
                    ...
                  </small>
                </span>  
              </template>
            </Column>
            <Column field="name" header="Detalle"></Column>
            <Column field="valor" header="Valor"></Column>
             <!-- <Column field="unidad" header="Unidad"></Column> -->
            <Column header="Subtotal">
                <template #body="slotProps">
                    <div class="flex justify-content-between">
                      <div>
                        <span>{{toUsd(slotProps.data.valor * slotProps.data.qty)}}</span>
                      </div>
                      <div>
                        <span>{{toArs(slotProps.data.valor * slotProps.data.qty)}}</span>
                      </div>
                    </div>
                
                </template>
            ></Column>
          </DataTable>

        </div>
      </div>

      <div class="card">
        <div class="my-1">
          <div class="col-12 section_subtitle">CIF <small>Costo Final</small></div>
        </div>


          <DataTable
            :value="calculos"
            dataKey="id" 
            class="p-datatable-sm"
            responsiveLayout="scroll"
          >
            <Column field="name" header="Detalle"></Column>
            <Column field="valor" exportableheader="Subtotal">
                <template #body="slotProps">
                    <div class="flex justify-content-between">
                      <div>
                        <span>{{toUsd(slotProps.data.valor)}}</span>
                      </div>
                      <div>
                        <span>{{toArs(slotProps.data.valor)}}</span>
                      </div>
                    </div>
                
                </template>
            ></Column>



          </DataTable>


      </div>  


    </div>

    <div class="col-6">

      <div class="card">
        <div class="my-1">
          <div class="col-12 section_subtitle">MARCO <small>Fórmula</small></div>

          <div class="" >
            <div class="formgrid grid">
              <div class="field col-2">
                <label for="alma">Alma</label>
                <InputText
                  v-model="form.marco.alma"
                  id="alma"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col-2">
                <label for="ala">Ala</label>
                <InputText
                  v-model="form.marco.ala"
                  id="ala"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col-2">
                <label for="marcoespesor">Espesor</label>
                <InputText
                  v-model="form.marco.espesor"
                  id="marcoespesor"
                  type="text"
                  class="inputfield w-full"
                />
              </div>

              <div class="field col-2">
                <label for="marcoancho">Ancho</label>
                <InputText
                  v-model="form.marco.ancho"
                  id="marcoancho"
                  type="text"
                  class="inputfield w-full"
                />
              </div>

            <div class="field col-2">
                <label for="marcolargo">Largo</label>
                <InputText
                  v-model="form.marco.largo"
                  id="marcolargo"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col-2">
                <label for="cantidad">Cantidad</label>
                <InputText
                  v-model="form.marco.cantidad"
                  id="cantidad"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
            </div>  
          </div>
        </div>

      </div>  

            <div class="card">
        <div class="my-1">
          <div class="col-12 section_subtitle">METAL DESPLEGADO <small>Formula</small></div>
        </div>

      </div>  

    </div>

  </div>
</template>

<script>
import CrudService from "./../../services/crud.service.js";

export default {
  data() {
    return {
      routeName: null,
      form: {
        name: "Malla",
        tipo: 0,
        alto:"600",
        ancho:"600",
        espesor:"300",
        marco:{
          largo:"595",
          ancho:"495",
          espesor:"0.4",
          cantidad:"300",
          alma:"48",
          ala:"20",
        },
        mod: [],
        items: [],
      },
      mod: [],
      items: [],

      actualFormula: "",
      products: null,
      filteredProducts: null,
      newItem: null,
      selectedProduct: null,

      mods: null,
      newItemMOD: null,
      filteredMod: null,
      selectedMod : null,
      actualFormulaMOD: "",

      submitted: false,
      validationErrors: null,


      dolarValue: 0,
      ciif: 0.17,
      mpi: 0.17,
      moi: 0.24,
      //calcQuantity: 0,
      tipos: [
        { value: 0, label: "Con plisas" },
        { value: 1, label: "Sin plisas" },
        { value: 2, label: "Filtros Cartucho" },
        { value: 3, label: "Lavable" },
      ],
      calidades: [
        { value: 0, label: "G2" },
        { value: 1, label: "G4" },
        { value: 2, label: "M5-M6" },
        { value: 3, label: "F7" },
        { value: 4, label: "F8-F9" },
      ],
      materials: [],

    };
  },
  computed: {
    totalMPD() {
      
      let total = 0;

      this.items.forEach(item => {
        console.log(item)
        total += item.valor * item.qty;
      });

      return total;

    },
    totalMOD() {
      
      let total = 0;

      this.mod.forEach(item => {
        console.log(item)
        total += item.valor * item.qty;
      });

      return total;

    },
    calculos() {
// Costo de Produccion
// MPI
// MOI
// CIIF
// Costo de General

      let data = 
      [
        {
          name: "Costo de Produccion",
          valor: this.totalMPD + this.totalMOD
        },
        {
          name: "MPI",
          valor: this.mpi
        },
        {
          name: "MOI",
          valor: this.moi
        },
        {
          name: "CIIF",
          valor: this.ciif
        },
        // {
        //   name: "Costo de General",
        //   valor:  this.totalMPD + this.totalMOD + this.mpi + this.moi + this.ciif
        // },
      ];

      return data

      
    },
    calcQuantity() {

      let qty = 0;
      if(this.newItem && this.actualFormula){

            // check if formula start with spaces
            if(this.actualFormula.charAt(0) == " "){
              this.actualFormula = this.actualFormula.substring(1);
            }

            // check if formula end with spaces
            if(this.actualFormula.charAt(this.actualFormula.length - 1) == " "){
              this.actualFormula = this.actualFormula.substring(0, this.actualFormula.length - 1);
            }

            // change all , to .
            this.actualFormula = this.actualFormula.replace(",", ".");

            //=SI(D4<30;B4*C4/1000000;2*B4*C4/1000000) => si espesor es menor a 30, alto por ancho / 1000000, si no, 2*alto por ancho / 1000000

            //=SI(D$4>30;0,17;0,083)

            //=SI(espesor>30;0,17;0,083)
            // =SI(espesor>30;0.17;0.083)

            //D4 ESPESOR
            //B4 ALTO
            //C4 ANCHO

            // =SI(espesor<30;alto*ancho/1000000; 2*alto*ancho / 1000000)
  


            qty = 1

            // check if formula start with =SI
            if(this.actualFormula.startsWith("=SI") || this.actualFormula.startsWith(" =SI")){
                // get formula inside of =SI(*)
                let condicion = this.actualFormula.substring(4, this.actualFormula.length - 1)

                 condicion = condicion.replace("B4", "alto")
                 condicion = condicion.replace("C4", "ancho")
                 condicion = condicion.replace("D4", "espesor")
                 condicion = condicion.replace("B4*C4", "alto*ancho")


                // explode formula by ;
                let condiciones = condicion.split(";")

                console.log(condiciones)


                let condicion1 = condiciones[0]



                condicion1 = condicion1.replace("alto", this.form.alto)
                condicion1 = condicion1.replace("ancho", this.form.ancho)
                condicion1 = condicion1.replace("espesor", this.form.espesor)


                if(eval(condicion1)){
                    // replace B4 with form.alto and C4 with form.ancho
                    let calcu = condiciones[1]
 

                    calcu = calcu.replace("alto", this.form.alto)
                    calcu = calcu.replace("ancho", this.form.ancho)
                    calcu = calcu.replace("espesor", this.form.espesor)



                    qty = eval(calcu)
                }else{
                    // replace B4 with form.alto and C4 with form.ancho
                    let calcu = condiciones[2]
                    calcu = calcu.replace("alto", this.form.alto)
                    calcu = calcu.replace("ancho", this.form.ancho)
                    calcu = calcu.replace("espesor", this.form.espesor)


                    qty = eval(calcu)

                }
                // replace , with .
                
                //qty = qty.toFixed(2)
                return qty;

            }else{

              // =+B4*C4*2/1000000
              // =+alto*ancho*2/1000000


              
              // replace B4 with form.alto and C4 with form.ancho
              let calcu = this.actualFormula

              if(this.actualFormula.startsWith("=")){
                calcu = calcu.substring(1, calcu.length)
              }

              calcu = calcu.replace("B4", "alto")
              calcu = calcu.replace("B4", "alto")
              calcu = calcu.replace("C4", "ancho")
              calcu = calcu.replace("D4", "espesor")

              calcu = calcu.replace("alto", this.form.alto)
              calcu = calcu.replace("ancho", this.form.ancho)
              calcu = calcu.replace("espesor", this.form.espesor)

              qty = eval(calcu)

              return qty;
            }

      }


      return qty
    },
  },
  created() {
    // get route name
    this.routeName = this.$route.name;
  },
  mounted() {
    CrudService.getCRUD("material", "material").then(
      (data) => (this.materials = data)
    );
    CrudService.getCRUD("insumos", "insumo").then(
      (data) => (this.products = data)
    );

    CrudService.getCRUD("mano-de-obra", "mano-de-obra").then(
      (data) => (this.mods = data)
    );
    CrudService.getCRUD("configuraciones").then(response => {
        this.dolarValue = response.dolarvalue; 
        this.ciif = response.ciif; 
        this.mpi = response.mpi; 
        this.moi = response.moi; 
    });
  },
  methods: {

    costoProd(){

      let costo = 0;

      return costo;

    },
    toArs(value) {
      let calc = value * this.dolarValue;
      // format number to currency
      return calc.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
    toUsd(value) {
      
      // format number to currency
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "USD",
      });
    },
delItem(itemId){
  //console.log(item)
  // find in this.items
  let index = this.items.findIndex(item => item.id == itemId);
  // remove item
  this.items.splice(index, 1);

  // recalculate total


},
delItemMOD(itemId){
  console.log(itemId)
  // find in this.items
  let index = this.mod.findIndex(item => item.id == itemId);
  // remove item
  this.mod.splice(index, 1);

  // recalculate total


},

      formatUnity(value) {

           switch(value) {
                case 0:
                    return "ml";
                case 1:
                    return "unidad";
                case 2:
                    return "kg";
                case 3:
                    return "mt";
                case 4:
                    return "lt";
                case 5:
                    return "m2";
                case 6:
                    return "mtl";
                case 7:
                    return "gr";
                case 8:
                    return "juego";
                default:
                    return "";
            }
      },
    displayErrors(errors) {
      for (let key in errors) {
        this.validationErrors.push({ field: key, message: errors[key] });

        let error = errors[key];
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: error[0],
          life: 3000,
        });
      }
    },
    searchProduct(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredProducts = [...this.products];
        } else {
          this.filteredProducts = this.products.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchMDO(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredMod = [...this.mods];
        } else {
          this.filteredMod = this.mods.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    addItem() {
      if (this.newItem) {
        console.log(this.newItem);

        let qty = this.calcQuantity;

        this.newItem.qty = qty

        this.items.push({
          id: this.newItem.id,
          name: this.newItem.name,
          description: this.newItem.description,
          unidad: this.newItem.unidad,
          valor: this.newItem.valor,
          qty: this.newItem.qty,
          formula: this.actualFormula,
        });

        this.actualFormula = null;
        this.newItem = null;
        this.selectedProduct = null;

      }
    },
    addItemMOD() {
      if (this.newItemMOD) {
        console.log(this.newItemMOD);

        // let qty = this.calcQuantity;

        // this.newItem.qty = qty

        this.mod.push({
          id: this.newItemMOD.id,
          name: this.newItemMOD.name,
          description: this.newItemMOD.description,
          unidad: this.newItemMOD.unidad,
          valor: this.newItemMOD.valor,
          qty: this.actualFormulaMOD,
          
        });

        this.actualFormulaMOD = null;
        this.newItemMOD = null;
        this.selectedMod = null;

      }
    },

    save() {
      this.submitted = true;

      this.form.mod = this.mod;
      this.form.mpd = this.items;

      CrudService.createCRUD("productos", "", this.form).then(
        (data) => {
          this.validationErrors = [];
          if (data.status == 400) {
            this.displayErrors(data.data);
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Creado correctamente.",
              life: 3000,
            });

            this.$router.push("/productos");
          }
        }
      );
    },
  },
};
</script>


<style scoped>

  .section_subtitle {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1261b1;
    margin-bottom: 1rem;
  }

  .card {
    padding-top: 0.25rem;
  }

</style>